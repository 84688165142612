export const BUGSNAG_APP_ID = 'shop-js';

// Set by rollup replace plugin
export const APP_VERSION = '__buildVersion';

export const SHOPIFY_S_COOKIE = '_shopify_s';
export const SHOPIFY_Y_COOKIE = '_shopify_y';

export const SHOW_LOGIN_WITH_SHOP_COOKIE = 'show_login_with_shop';
export const SIGN_IN_WITH_SHOP_MODAL_DISMISSED_COOKIE =
  'sign_in_with_shop_modal_dismissed';

export const SPIN_URL_SUFFIX = /\.(?:(?:\w|-)+\.){3}spin\.dev$/;

export const POPUP_CLOSE_CHECK_INTERVAL = 1000;

export const SIGN_IN_FORM_DATA_ATTRIBUTE = 'data-login-with-shop-sign-in';
export const SIGN_UP_FORM_DATA_ATTRIBUTE = 'data-login-with-shop-sign-up';

export const SIGN_IN_FORM_SELECTOR = `form[${SIGN_IN_FORM_DATA_ATTRIBUTE}] input[type="email"],form[${SIGN_IN_FORM_DATA_ATTRIBUTE}] input[name="customer[email]"]`;
export const SIGN_UP_FORM_SELECTOR = `form[${SIGN_UP_FORM_DATA_ATTRIBUTE}] input[type="email"],form[${SIGN_UP_FORM_DATA_ATTRIBUTE}] input[name="customer[email]"]`;
